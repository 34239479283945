require("./bootstrap");

import InstantSearch from "vue-instantsearch";
import SocialSharing from "vue-social-sharing";
import Datepicker from "vuejs-datepicker";
import Vue from "vue";
import VTooltip from "v-tooltip";

window.Vue = Vue;

Vue.use(VTooltip);
Vue.use(InstantSearch);
Vue.use(SocialSharing);

import accordion from "./components/Accordion.vue";
import aisRefinementSelect from "./components/search/ais-refinement-select.vue";
import aisResultsLang from "./components/search/ais-results-lang.vue";
import aisResultsCircles from "./components/search/ais-results-circles.vue";
import adminHeader from "./components/AdminHeader.vue";
import adminNav from "./components/AdminNav.vue";
import ellipsisDropdown from "./components/EllipsisDropDown.vue";

import navbar from "./components/Nav.vue";
import spotPatterns from "./components/SpotPatterns.vue";
import usersAnimated from "./components/UsersAnimated.vue";
import accountSteps from "./components/AccountSteps.js";
import autoSave from "./components/AutoSave.vue";

import aisNumericFilterCheckbox from "./components/search/ais-numeric-filter-checkbox.vue";
import quickReadSwitch from "./components/QuickReadSwitch.vue";
import languageSelect from "./components/LanguageSelect.vue";
import aisCustomPagination from "./components/AisCustomPagination.vue";
import sortableAdminListingItem from "./components/sort-admin-listing/SortableAdminListingItem.vue";
import sortableAdminListingSave from "./components/sort-admin-listing/SortableAdminListingSave.vue";
import sortableAdminListing from "./components/sort-admin-listing/SortableAdminListing.vue";

import saveButton from "./components/SaveButton.vue";

Vue.component("accordion", accordion);
Vue.component("ais-refinement-select", aisRefinementSelect);
Vue.component("aisResultsLang", aisResultsLang);
Vue.component("aisResultsCircles", aisResultsCircles);
Vue.component("admin-header", adminHeader);
Vue.component("admin-nav", adminNav);
Vue.component("ellipsisDropdown", ellipsisDropdown);
Vue.component("navbar", navbar);
Vue.component("spotPatterns", spotPatterns);
Vue.component("usersAnimated", usersAnimated);
Vue.component("accountSteps", accountSteps);
Vue.component("autoSave", autoSave);
Vue.component("aisNumericFilterCheckbox", aisNumericFilterCheckbox);
Vue.component("quickReadSwitch", quickReadSwitch);
Vue.component("languageSelect", languageSelect);
Vue.component("aisCustomPagination", aisCustomPagination);
Vue.component("sortableAdminListingItem", sortableAdminListingItem);
Vue.component("sortableAdminListingSave", sortableAdminListingSave);
Vue.component("sortableAdminListing", sortableAdminListing);
Vue.component("saveButton", saveButton);

// SEARCH VUE ALGOLA Instants
if (document.getElementById("app")) {
  new Vue({
    el: "#app",
    components: {
      Datepicker,
    },
  });
}

/**
 * Toggle Nav Menu
 *
 * Toggles the nav slideout on mobile; toggles logo visibility
 * and header background color/state as well, as there is some
 * overlap due to the header logo/menu toggles being position
 * fixed.
 */
if (document.getElementById("nav-slideout-toggle")) {
  const navSlideoutToggle = document.querySelector("#nav-slideout-toggle");
  const navSlideoutArea = document.querySelector("#nav-slideout-toggle-area");
  const mainHeader = document.querySelector("#main-header");
  const biorgLogo = document.querySelector("#biorg-logo");
  const menuToggleOpen = document.querySelector("#toggle-open");
  const menuToggleClose = document.querySelector("#toggle-close");

  navSlideoutToggle.onclick = function() {
    navSlideoutArea.classList.toggle("is-open");
    mainHeader.classList.toggle("hide-header-scroll-bg");
    biorgLogo.classList.toggle("sr-only");
    menuToggleOpen.classList.toggle("icon-hide");
    menuToggleClose.classList.toggle("icon-hide");
  };
}

/**
 * Expanding Textarea
 *
 * @see http://alistapart.com/article/expanding-text-areas-made-elegant
 */

function makeExpandingArea(container) {
  const area = container.querySelector("textarea");
  const span = container.querySelector("span");
  if (area.addEventListener) {
    area.addEventListener(
      "input",
      function() {
        span.textContent = area.value;
      },
      false
    );
    span.textContent = area.value;
  } else if (area.attachEvent) {
    // IE8 compatibility
    area.attachEvent("onpropertychange", function() {
      span.innerText = area.value;
    });
    span.innerText = area.value;
  }

  // Enable extra CSS
  container.className += " active";
  console.log("Textareas expand");
}
const areas = document.querySelectorAll(".expanding-textarea");
let l = areas.length;
while (l--) {
  makeExpandingArea(areas[l]);
}

window.onload = function() {
  console.log("All assets are loaded");
  var headerNavHeight = document.getElementById("header-nav").clientHeight;
  var anchoredContents = document.getElementsByClassName("glossary-anchor");
  console.log("Bop");
  for (i = 0; i < anchoredContents.length; i++) {
    anchoredContents[i].style.display = "block";
    anchoredContents[i].style.position = "relative";
    anchoredContents[i].style.top = "-" + headerNavHeight + "px";
  }

  function decodeHtml(str) {
    return str
      .replace(/##amp##/g, "&")
      .replace(/##lt##/g, "<")
      .replace(/##gt##/g, ">")
      .replace(/##quot##/g, '"')
      .replace(/##sq##/g, "'");
  }

  function updateCitation(el) {
    //var isAdmin = @json(strpos(request()->route()->getAction('namespace'), 'Admin') !== false);
    var isAdmin;
    var redactorId = $(el).attr("id") ? $(el).attr("id") : "citation";
    var containerHtml = "";
    $(el)
      .parent()
      .find(".redactor-citation-item")
      .each(function(index) {
        var k = index + 1;
        $(this).html("[" + k + "]");
        $(this).attr("href", "#" + redactorId + "-ref" + k);
        containerHtml +=
          '<li id="' +
          redactorId +
          "-ref" +
          k +
          '">' +
          decodeHtml($(this).data("citation-label")) +
          ' <a target="_blank" class="text-grey-dark text-sm no-underline" href="' +
          decodeHtml($(this).data("citation-url")) +
          '">' +
          decodeHtml($(this).data("citation-url")) +
          "</a></li>";
      });

    if (containerHtml) {
      containerHtml = isAdmin
        ? '</div><label class="">Citations: <button type="button" class="refresh-citation">(Refresh)</button></label><ol class="citation-ol">' +
          containerHtml +
          "</ol>"
        : '</div><ol class="citation-ol text-sm text-indigo">' +
          containerHtml +
          "</ol>";
      $(el).html(containerHtml);
    }
  }

  $(function() {
    $(".redactor-citation-container").each(function(index) {
      updateCitation($(this));
    });

    $(document).on("click", ".refresh-citation", function() {
      updateCitation($(this).closest(".redactor-citation-container"));
    });
  });
};
